import React, {useEffect} from 'react';
import './styles.scss';
import {Link} from "gatsby";
import {LocaleType} from "../../../interfaces/Locale";
export default function Head({locale,lang}:{locale:LocaleType,lang:string}){
    useEffect(()=>{
        const stickyHeight= document.getElementById('notif')?.offsetHeight;
        const ele= document.getElementById('nav');
        if(ele&&stickyHeight){
            ele.style.paddingTop=(stickyHeight+27).toString()+'px';
        }
    },[])
    return <div className="head">
        <div id="notif" className="notif">
            {locale["Layout.Head.head.title"]} <a href="https://wa.me/13474801260" target="_blank">{locale["Layout.Head.head.contactNow"]}</a>
        </div>
        <div id="nav" className="nav">
            <Link to={(lang==="en"?"/":`/${lang}`)}><div className="logo">
                {locale["Layout.Head.nav.title"]}
            </div></Link>
            <div className="links">
                <Link className="link" to={(lang==="en"?"":`/${lang}`)+"/blogs"}>{locale["Layout.Head.nav.link1"]}</Link>
                <a href="https://wa.me/13474801260" target="_blank" className="link">{locale["Layout.Head.nav.link3"]}</a>
            </div>
        </div>
    </div>
}
