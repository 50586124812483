import React from 'react';
import Head from "./Head";
import Foot from "./Foot";
import Floating from "../Floating";
export default function Layout({children,locale,lang}:any){
    return <div>
        <Floating/>
        <Head locale={locale} lang={lang}/>
        {children}
        <Foot  locale={locale}/>
    </div>
}